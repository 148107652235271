exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admission-details-js": () => import("./../../../src/pages/admission-details.js" /* webpackChunkName: "component---src-pages-admission-details-js" */),
  "component---src-pages-campus-js": () => import("./../../../src/pages/campus.js" /* webpackChunkName: "component---src-pages-campus-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-hostel-js": () => import("./../../../src/pages/hostel.js" /* webpackChunkName: "component---src-pages-hostel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-js": () => import("./../../../src/pages/infrastructure.js" /* webpackChunkName: "component---src-pages-infrastructure-js" */),
  "component---src-pages-laboratory-js": () => import("./../../../src/pages/laboratory.js" /* webpackChunkName: "component---src-pages-laboratory-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-management-js": () => import("./../../../src/pages/management.js" /* webpackChunkName: "component---src-pages-management-js" */),
  "component---src-pages-non-teaching-faculty-js": () => import("./../../../src/pages/nonTeaching-faculty.js" /* webpackChunkName: "component---src-pages-non-teaching-faculty-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photoGallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-principal-desk-js": () => import("./../../../src/pages/principal-desk.js" /* webpackChunkName: "component---src-pages-principal-desk-js" */),
  "component---src-pages-teaching-faculty-js": () => import("./../../../src/pages/teaching-faculty.js" /* webpackChunkName: "component---src-pages-teaching-faculty-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

